import React, { FC } from 'react';

import { AvatarCircle, AvatarIconStyled } from './styled';

export type AvatarProps = {
  userName?: string;
  email?: string;
};

// TODO: pass username somewhere
export const Avatar: FC<AvatarProps> = ({ userName, email }) => {
  const userNameInitials = (userName: string) => {
    // eslint-disable-next-line prefer-const
    let names = userName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  const content = userName ? userNameInitials(userName) : <AvatarIconStyled />;

  return (
    <div title={userName || email}>
      <AvatarCircle>{content}</AvatarCircle>
    </div>
  );
};

import { useUserContext } from '@hp/context';
import React, { FC } from 'react';

import { Navigation } from './components';
import { AccountNavLinkGroup, UnauthenticatedLinkGroup } from './navLinkGroups';
import { AccountNavLinkGroupProps } from './navLinkGroups/types';

export const DefaultNavigation: FC<AccountNavLinkGroupProps> = (props) => {
  const { user, loading } = useUserContext();

  if (loading) return <Navigation />;

  return (
    <Navigation logoRouteTo={user ? 'accountHomepage' : undefined}>
      {user ? <AccountNavLinkGroup {...props} /> : <UnauthenticatedLinkGroup />}
    </Navigation>
  );
};

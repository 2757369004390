import {
  ButtonType,
  Link,
  LinkButton,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { HideOnDesktop, HideOnMobile } from '@hp/layout';
import { RouteNameType } from '@hp/seo';
import { colors } from '@hp/theme';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';
import styled from 'styled-components';

import { NavLinkProps } from './types';
import { useActiveLink } from './useActiveLink';

const LinkStyled = styled(Link)`
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const ParcelShopsNavLink: FC<NavLinkProps> = ({
  setOpen,
  hideOnDesktop,
  hideOnMobile,
}) => {
  const to: RouteNameType = 'accountParcelShops';

  const { active } = useActiveLink(to);

  const closeMenu = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setOpen(false);
  };

  const content = !hasFeature('parcelShopsMapIntegration') ? (
    <LinkStyled href="https://pickup.dpd.cz/">
      <Typography type={TypographyType.BodyLight} color={colors.black}>
        <Trans id="navigation.parcelShops">Pobočky DPD</Trans>
      </Typography>
    </LinkStyled>
  ) : (
    <LinkButton
      buttonType={ButtonType.MENU_TEXT}
      to={to}
      onClickOverride={
        active &&
        !!setOpen &&
        ((e: { preventDefault: () => void }) => closeMenu(e))
      }
    >
      <Typography
        type={TypographyType.BodyLight}
        color={active ? colors.red_dark : colors.black}
      >
        <Trans id="navigation.parcelShops">Pobočky DPD</Trans>
      </Typography>
    </LinkButton>
  );

  if (hideOnDesktop) {
    return <HideOnDesktop>{content}</HideOnDesktop>;
  }

  if (hideOnMobile) {
    return <HideOnMobile>{content}</HideOnMobile>;
  }

  return <>{content}</>;
};

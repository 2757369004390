import { Language } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { LanguageSelect } from '@hp/form/src/atomic/LanguageSelect';
import React, { FC } from 'react';

export const LanguageSelectorV2: FC = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <LanguageSelect
      value={language}
      onChange={(e) => setLanguage((e.target as any).value as Language)}
    />
  );
};

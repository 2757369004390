import { HideOnDesktop } from '@hp/layout';
import { hasFeature } from '@hp/utils';
import React, { FC } from 'react';

import {
  LanguageSelectorDropdown,
  LanguageSelectorV2,
  NavCollapsible,
} from '../components';
import { Avatar } from '../components/Avatar';
import { Dropdown } from '../components/Dropdown';
import {
  ArchiveNavLink,
  HomeNavLink,
  LogoutNavLink,
  ParcelShopsNavLink,
  ProfileNavLink,
  SendPackageNavLink,
} from '../navLinks';
import { AccountNavLinkGroupProps } from './types';

export const AccountNavLinkGroup: FC<AccountNavLinkGroupProps> = ({
  hideHomeOnDesktop,
  noSendButton,
}) => {
  const avatarDropdownItems = [
    <ProfileNavLink key="item0" />,
    <LogoutNavLink key="item1" />,
  ];

  return (
    <NavCollapsible>
      {/* here we should not use HideOnMobile, because it wraps empty child and creates margins,
          implement it in items css if possible */}
      <HomeNavLink hideOnDesktop={hideHomeOnDesktop} />

      <ArchiveNavLink />

      {hasFeature('parcelShopsMapIntegration') && <ParcelShopsNavLink />}

      <ProfileNavLink hideOnDesktop />

      {!noSendButton && <SendPackageNavLink />}

      <LogoutNavLink hideOnDesktop />

      <Dropdown items={avatarDropdownItems} hideOnMobile>
        <Avatar />
      </Dropdown>

      <LanguageSelectorDropdown hideOnMobile />

      <HideOnDesktop>
        <LanguageSelectorV2 />
      </HideOnDesktop>
    </NavCollapsible>
  );
};

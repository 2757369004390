import { ButtonType, LinkButton, Typography, TypographyType } from '@hp/atomic';
import { HideOnDesktop, HideOnMobile } from '@hp/layout';
import { RouteNameType } from '@hp/seo';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { NavLinkProps } from './types';
import { useActiveLink } from './useActiveLink';

export const ArchiveNavLink: FC<NavLinkProps> = ({
  setOpen,
  hideOnDesktop,
  hideOnMobile,
}) => {
  const to: RouteNameType = 'accountArchive';

  const { active } = useActiveLink(to);

  const closeMenu = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setOpen(false);
  };

  const content = (
    <LinkButton
      buttonType={ButtonType.MENU_TEXT}
      to={to}
      onClickOverride={
        active &&
        !!setOpen &&
        ((e: { preventDefault: () => void }) => closeMenu(e))
      }
    >
      <Typography
        type={TypographyType.BodyLight}
        color={active ? colors.red_dark : colors.black}
      >
        <Trans id="navigation.archive">Moje zásilky</Trans>
      </Typography>
    </LinkButton>
  );

  if (hideOnDesktop) {
    return <HideOnDesktop>{content}</HideOnDesktop>;
  }

  if (hideOnMobile) {
    return <HideOnMobile>{content}</HideOnMobile>;
  }

  return <>{content}</>;
};

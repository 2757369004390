import { RouteNameType } from '@hp/seo';
import React, { FC } from 'react';

import { Logo, LogoLink, LogoWrapper, Nav } from './styled';

type NavigationProps = {
  hideLogoOnNarrow?: boolean;
  logo?: JSX.Element;
  logoRouteTo?: RouteNameType;
};

export const Navigation: FC<NavigationProps> = ({
  children,
  hideLogoOnNarrow,
  logo = <Logo />,
  logoRouteTo = 'landing',
}) => {
  return (
    <>
      <Nav>
        <LogoWrapper hideLogoOnNarrow={hideLogoOnNarrow}>
          <LogoLink to={logoRouteTo} key="logo">
            {logo}
          </LogoLink>
        </LogoWrapper>
        {children}
      </Nav>
    </>
  );
};

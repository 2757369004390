import { ButtonType, LinkButton } from '@hp/atomic';
import { HideOnDesktop } from '@hp/layout';
import { Trans } from '@lingui/macro';
import React from 'react';

import {
  LanguageSelectorDropdown,
  LanguageSelectorV2,
  NavCollapsible,
} from '../components';

export const UnauthenticatedLinkGroup = () => {
  return (
    <NavCollapsible>
      <LinkButton
        buttonType={ButtonType.MENU_SECONDARY}
        to="register"
        className="gaButton gaButtonAction_login_register"
      >
        <Trans id="navigation.register">Přihlášení / Vytvoření účtu</Trans>
      </LinkButton>

      <LanguageSelectorDropdown hideOnMobile />

      <HideOnDesktop>
        <LanguageSelectorV2 />
      </HideOnDesktop>
    </NavCollapsible>
  );
};

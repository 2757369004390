import { Button, ButtonType } from '@hp/atomic';
import { useRouter } from '@hp/seo';
import { Trans } from '@lingui/react';
import React from 'react';

export const SendPackageNavLink = () => {
  const router = useRouter();

  return (
    <Button
      buttonType={ButtonType.MENU_PRIMARY}
      className="gaButton gaButtonAction_sendPackage"
      onClick={() => router.nextRouter.push('/account#send')} // TODO: fix our router (changes # for %23)
    >
      <span className="gaButton gaButtonAction_sendPackage">
        <Trans id="navigation.sendPackage">Poslat balík</Trans>
      </span>
    </Button>
  );
};

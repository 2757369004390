import { DPDLogo, NextLink } from '@hp/atomic';
import { breakpoints, colors, elements, misc } from '@hp/theme';
import styled, { css } from 'styled-components';

export const LogoLink = styled(NextLink)`
  text-decoration: none;
  line-height: 0;
`;

export const NextLinkStyled = styled(NextLink)`
  text-decoration: none;
  color: ${colors.black};

  :after {
    content: ' ';
    display: block;
    background: ${({ active }) =>
      active ? colors.red_main : colors.transparent};
    border-radius: 5px;
    height: 3px;
    width: 100%;
    position: relative;
    bottom: -2px;
    left: 0;
  }
`;

export const Nav = styled.nav`
  position: absolute;
  line-height: 0;
  background-color: ${colors.white};
  height: ${elements.navigationHeightMobile};
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid ${colors.gray_light};
  box-shadow: ${misc.shadow_menu};
  z-index: 30;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${breakpoints.minDesktop}) {
    position: fixed;
    height: ${elements.navigationHeight};
  }
`;

export const LogoWrapper = styled.div<{ hideLogoOnNarrow?: boolean }>`
  position: absolute;
  left: 16px;
  bottom: 10px;

  @media (min-width: ${breakpoints.minDesktop}) {
    bottom: initial;
  }

  ${({ hideLogoOnNarrow }) =>
    hideLogoOnNarrow &&
    css`
      display: none;

      @media (min-width: ${elements.desktopContainerWidthNum +
        elements.logoWidthNum +
        24}px) {
        display: block;
      }
    `}
`;

export const Logo = styled(DPDLogo)`
  height: 32px;
  width: auto;
`;

export const BurgerStyled = styled.button<{ open: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 56px;
  overflow: visible;
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  border: none;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: none;
  }

  div {
    width: 30px;
    height: 3px;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    ${({ open }) =>
      open
        ? css`
            background-color: ${colors.gray_mid};
            &:nth-child(1) {
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              transform: translateX(100%);
              opacity: 0;
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
          `
        : css`
            background-color: ${colors.black};
            &:nth-child(1) {
              transform: rotate(0);
            }
            &:nth-child(2) {
              transform: translateX(0);
              opacity: 1;
            }
            &:nth-child(3) {
              transform: rotate(0);
            }
          `}
  }
`;

export const Overlay = styled.div<{ visible: boolean }>`
  z-index: 29;
  position: absolute;
  background: black;
  top: calc(${elements.navigationHeightMobile} - 10px);
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: -100vh;
  transition: opacity 0.3s;
  opacity: ${({ visible }) => (visible ? 0.8 : 0)};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: none;
  }
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const MenuList = styled.ul<{ open: boolean }>`
  position: fixed;
  z-index: 31;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${colors.white};
  padding: 0;
  top: calc(${elements.navigationHeightMobile} - 10px);
  right: 0;
  height: auto;
  min-height: 60vh;
  max-height: 100vh;
  width: 100%;
  margin: 0;
  transform-origin: top center;
  transform: ${({ open }) => (open ? 'scale(1,1)' : 'scale(1,0)')};
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  box-shadow: ${misc.shadow_menu_notOnTop};
  line-height: 24px;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-flow: row nowrap;
    position: relative;
    min-height: auto;
    max-height: auto;
    height: auto;
    width: auto;
    transform: none;
    transition: none;
    top: 0px;
    right: 0px;
    box-shadow: none;
  }
`;

export const MenuBorder = styled.hr`
  width: 100%;
  display: block;
  border-top: 1px solid ${colors.gray_light};
  border-bottom: none;
  margin-top: 15px;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: none;
  }
`;

import { breakpoints } from '@hp/theme';
import { scrollToTop, toArray, useWindowSize } from '@hp/utils';
import React, { FC, useState } from 'react';
import ScrollLock from 'react-scrolllock';

import { Burger } from './Burger';
import { MenuItem } from './MenuItem';
import { MenuList, Overlay } from './styled';

export const NavCollapsible: FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const windowSize = useWindowSize();

  if (!children) return null;

  const childrenArray = toArray(children);

  const setOpenMenu = (isOpen: boolean) => {
    scrollToTop();
    setOpen(isOpen);
  };

  return (
    <>
      <MenuList open={open}>
        {childrenArray.map((child, index) => {
          // check if we should hide on desktop or mobile, this way due to menu item implementation
          const hide =
            (windowSize.width < breakpoints.minDesktopNum &&
              //@ts-ignore
              child?.props?.hideOnMobile) ||
            (windowSize.width >= breakpoints.minDesktopNum &&
              //@ts-ignore
              child?.props?.hideOnDesktop);

          return (
            <MenuItem
              key={index}
              open={open}
              isEmpty={!child || hide}
              setOpen={setOpenMenu}
            >
              {child}
            </MenuItem>
          );
        })}
      </MenuList>

      <Overlay visible={open} />

      {open && <ScrollLock />}

      <Burger open={open} setOpen={setOpenMenu} />
    </>
  );
};

import { AvatarIcon } from '@hp/atomic';
import { colors } from '@hp/theme';
import styled from 'styled-components';

export const AvatarCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  color: ${colors.white};
  background-color: ${colors.gray_warm};
  border-radius: 50%;
  margin: auto;
`;

export const AvatarIconStyled = styled(AvatarIcon)`
  position: relative;
  left: 3px;
`;

import { Button, ButtonType, Typography, TypographyType } from '@hp/atomic';
import { useUserContext } from '@hp/context';
import { HideOnDesktop, HideOnMobile } from '@hp/layout';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { NavLinkProps } from './types';

export const LogoutNavLink: FC<NavLinkProps> = ({
  hideOnDesktop,
  hideOnMobile,
}) => {
  const { logout } = useUserContext();

  const content = (
    <Button buttonType={ButtonType.MENU_TEXT} onClick={() => logout()}>
      <Typography type={TypographyType.BodyLight} color={colors.black}>
        <Trans id="navigation.logout">Odhlásit se</Trans>
      </Typography>
    </Button>
  );

  if (hideOnDesktop) {
    return <HideOnDesktop>{content}</HideOnDesktop>;
  }

  if (hideOnMobile) {
    return <HideOnMobile>{content}</HideOnMobile>;
  }

  return <>{content}</>;
};

import { RouteNameType, routes, useRouter } from '@hp/seo';

export const useActiveLink = (to: RouteNameType) => {
  const router = useRouter();

  const pathName = router?.pathname.split('/');
  pathName?.shift();

  const [, pathSegment1, pathSegment2] = routes[to]?.href.toString().split('/');

  const active = pathName[0] === pathSegment1 && pathName[1] === pathSegment2;

  return { active };
};
